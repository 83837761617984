import inventoryComponent from "./inventory/inventory.component.vue";
import paymentComponent from "./payment/payment.component.vue";
import profileComponent from "./profile/profile.component.vue";
import registerComponent from "./register/register.component.vue";
import noticeComponent from "./notice/form.component.vue";
import flightComponent from "./flight/flight.component.vue";
import calendarComponent from "./calendar/calendar.component.vue";

export default {
    components: {
        inventoryComponent,
        paymentComponent,
        profileComponent,
        registerComponent,
        noticeComponent,
        flightComponent,
        calendarComponent
    },
    data() {
        return {
            show: 'profile'
        }
    },
    async mounted() {
    },
    methods: {
    }

}