import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilitySettingFlightService {
    upload(data) {
        promise = api.client.post(config.end_point.facility.airport.upload, data);
        return promise;
    }
    
    update(id, data) {
        promise = api.client.put(config.end_point.facility.airport.flight + '/' + id, data);
        return promise;
    }

    async list(data) {
        promise = await api.client.get(config.end_point.facility.airport.flight, {params: data});
        return promise;
    }

    store(data) {
        promise =  api.client.post(config.end_point.facility.airport.flight, data);
        return promise;
    }

    delete(id) {
        promise = api.client.delete(config.end_point.facility.airport.flight + '/' + id);
        return promise;
    }
}

export const facilitySettingFlightService = new FacilitySettingFlightService();
