import { alertService } from "@/services/alert/alert.service";
import { facilitySettingFlightService } from "@/services/facility/setting/flight.service";
import PaginateComponent from "@/resources/components/paginate.component";
import moment from "moment";

export default {
    components: { PaginateComponent },
    data() {
        return {
            filter: {
                paginate: 1,
                per_page: 10,
            },
            data: [],
            dataReady: false,
            form: {},
            error: null,
            file: null,
        };
    },
    async mounted() {
        this.list(this.filter, { page: 1 });
    },
    methods: {
        async list(query, data, form) {
            this.dataReady = false;
            alertService.loading();
            await facilitySettingFlightService
                .list({ ...query, ...data })
                .then((response) => {
                    this.data = response.data;
                    this.dataReady = true;
                    if(form == 'add') {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.global.added-success")
                        );
                    } else if (form == 'update') {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.global.updated-success")
                        );
                    } else if (form == 'delete') {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.global.deleted-success")
                        );
                    } else {
                        alertService.close();
                    }
                })
                .catch((error) => {
                    alertService.close();
                    console.log(error);
                });
        },

        async submit$() {
            if (this.form.type == "new" || this.form.type == "copy") {
                this.add$();
            }
            if (this.form.type == "update") {
                this.update$();
            }
            if (this.form.type == "delete") {
                this.delete$(this.form.id);
            }
        },

        async add$() {
            alertService.loading();
            this.error = null;
            this.form.valid_from = moment(this.form.valid_from).format(
                "MM/DD/YYYY"
            );
            this.form.valid_to = moment(this.form.valid_to).format(
                "MM/DD/YYYY"
            );
            await facilitySettingFlightService
                .store(this.form)
                .then((response) => {
                    this.hideModal();
                    this.list(this.filter, { page: 1 }, 'add');
                })
                .catch((error) => {
                    alertService.close();
                    this.error = error.message;
                    console.log(error);
                });
        },

        async update$() {
            alertService.loading();
            this.error = null;
            await facilitySettingFlightService
                .update(this.form.id, this.form)
                .then((response) => {
                    this.hideModal();
                    this.list(this.filter, { page: 1 }, 'update');
                })
                .catch((error) => {
                    alertService.close();
                    this.error = error.message;
                    console.log(error);
                });
        },

        async upload$() {
            alertService.loading();
            this.error = null;
            let formData = new FormData();
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            formData.append('file', this.file);
            await facilitySettingFlightService
                .upload(formData, config)
                .then((response) => {
                    this.hideUploadModal();
                    this.list(this.filter, { page: 1 });
                    alertService.success(
                        this.$t("facility.global.success"),
                        this.$t("facility.global.success")
                    );
                })
                .catch((error) => {
                    alertService.close();
                    this.error = error.message;
                    console.log(error);
                });
        },

        async delete$(id) {
            await facilitySettingFlightService.delete(id);
            this.hideModal();
            this.list(this.filter, { page: 1 }, 'delete');
        },

        showModal(type, data) {
            this.error = null;
            if (type == "new") {
                this.form = {
                    airline_company_en: "",
                    airline_company_jp: "",
                    airline_code: "",
                    flight_number: "",
                    departure_port: "",
                    arrival_port: "",
                    departure_time: "",
                    arrival_time: "",
                    valid_from: "",
                    valid_to: "",
                    type: type,
                };
            }
            if (type == "update" || type == "copy" || type == "delete") {
                this.form = {
                    id: data.id,
                    airline_company_en: data.airline_company_en,
                    airline_company_jp: data.airline_company_jp,
                    airline_code: data.airline_code,
                    flight_number: data.flight_number,
                    departure_port: data.departure_port,
                    arrival_port: data.arrival_port,
                    departure_time: data.departure_time,
                    arrival_time: data.arrival_time,
                    valid_from: moment(data.valid_from).format("YYYY-MM-DD"),
                    valid_to: moment(data.valid_to).format("YYYY-MM-DD"),
                    type: type,
                };
            }
            this.$bvModal.show("flight-info-modal");
        },

        hideModal() {
            this.$bvModal.hide("flight-info-modal");
        },

        showUploadModal() {
            this.error = null;
            this.file = null
            this.$bvModal.show("flight-upload-modal");
        },

        hideUploadModal() {
            this.$bvModal.hide("flight-upload-modal");
        },

        async search$(data) {
            this.list(this.filter, data);
        },

        async validateFile(e) {
            this.error = null
            const file = e.target.files[0]
            let ext = file['name'].lastIndexOf('.');
            ext = (ext !== -1) ? file['name'].substring(ext + 1)  : '';
            const types = ['csv']
            if (!types.includes(ext)) {
                this.error = this.$t("facility.setting.file-error")
                this.file = null
                // this line right below will reset the input field so if you removed it you can re-add the same file
                e.target.value = ''
            }
        },
    },
};
