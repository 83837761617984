import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilitySettingProfileService {
    show(id) {
        promise = api.client.get(config.end_point.facility.airport.details + '/' + id);
        return promise;
    }
    
    update(id, data) {
        promise = api.client.put(config.end_point.facility.airport.details + '/' + id, data);
        return promise;
    }
}

export const facilitySettingProfileService = new FacilitySettingProfileService();
