import { alertService } from "@/services/alert/alert.service";
import { facilitySettingCalendarService } from "@/services/facility/setting/calendar.service";
import PaginateComponent from "@/resources/components/paginate.component";
import moment from "moment";

export default {
    components: { PaginateComponent },
    data() {
        return {
            filter: {
                paginate: 1,
                per_page: 10,
            },
            data: [],
            dataReady: false,
            form: {},
            error: null,
            file: null,
        };
    },
    async mounted() {
        this.list(this.filter, { page: 1 });
    },
    methods: {
        async list(query, data) {
            this.dataReady = false;
            alertService.loading();
            await facilitySettingCalendarService
                .list({ ...query, ...data })
                .then((response) => {
                    this.data = response.data;
                    this.dataReady = true;
                    alertService.close();
                })
                .catch((error) => {
                    alertService.close();
                    console.log(error);
                });
        },

        async show(update) {
            alertService.loading();
            this.settingReady = false;
            const response = await facilitySettingPaymentRegisterService.show(
                this.params
            );
            if (response.data.length > 0) this.setting = response.data[0];
            this.settingReady = true;
            alertService.close();
            if (update) {
                alertService.success(
                    this.$t("facility.setting.register-information"),
                    this.$t("facility.setting.updated-successfuly")
                );
            }
        },

        async add$() {
            alertService.loading();
            this.error = null;
            await facilitySettingCalendarService
                .store(this.form)
                .then((response) => {
                    this.hideModal();
                    this.list(this.filter, { page: 1 });
                    alertService.success(
                        this.$t("facility.global.success"),
                        this.$t("facility.global.success")
                    );
                })
                .catch((error) => {
                    alertService.close();
                    this.error = error.message;
                    console.log(error);
                });
        },

        async update$() {
            alertService.loading();
            this.error = null;
            await facilitySettingCalendarService
                .update(this.form.id, this.form)
                .then((response) => {
                    this.hideModal();
                    this.list(this.filter, { page: 1 });
                    alertService.success(
                        this.$t("facility.global.success"),
                        this.$t("facility.global.success")
                    );
                })
                .catch((error) => {
                    alertService.close();
                    this.error = error.message;
                    console.log(error);
                });
        },

        async upload$() {
            alertService.loading();
            this.error = null;
            let formData = new FormData();
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            formData.append('file', this.file);
            await facilitySettingCalendarService
                .upload(formData, config)
                .then((response) => {
                    this.hideUploadModal();
                    this.list(this.filter, { page: 1 });
                    alertService.success(
                        this.$t("facility.global.success"),
                        this.$t("facility.global.success")
                    );
                })
                .catch((error) => {
                    alertService.close();
                    this.error = error.message;
                    console.log(error);
                });
        },

        async delete$(id) {
            var callback = async () => {
                await facilitySettingCalendarService.delete(id)
                this.list(this.filter, { page: 1 });
                alertService.success(this.$t('facility.global.success'), this.$t('facility.global.success'));
            }
            alertService.confirmation(callback, this.$t('facility.global.confirmation'), this.$t('facility.global.cannot-reverted'));
        },

        showModal(data) {
            this.error = null;
            if (data) {
                this.form = {
                    id: data.id,
                    date: data.date,
                    name_display_jp: data.name_display_jp,
                    name_display_en: data.name_display_en,
                }
            } else {
                this.form = {
                    date: "",
                    name_display_jp: "",
                    name_display_en: "",
                };
            }
            this.$bvModal.show("calendar-modal");
        },

        hideModal() {
            this.$bvModal.hide("calendar-modal");
        },

        showUploadModal() {
            this.error = null;
            this.file = null
            this.$bvModal.show("calendar-upload-modal");
        },

        hideUploadModal() {
            this.$bvModal.hide("calendar-upload-modal");
        },

        async search$(data) {
            this.list(this.filter, data);
        },

        async validateFile(e) {
            this.error = null
            const file = e.target.files[0]
            let ext = file['name'].lastIndexOf('.');
            ext = (ext !== -1) ? file['name'].substring(ext + 1)  : '';
            const types = ['csv']
            if (!types.includes(ext)) {
                this.error = this.$t("facility.setting.file-error")
                this.file = null
                // this line right below will reset the input field so if you removed it you can re-add the same file
                e.target.value = ''
            }
        },
    },
};
