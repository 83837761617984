import {alertService} from "@/services/alert/alert.service";
import {facilitySettingPaymentRegisterService} from "@/services/facility/setting/payment_register.service";

export default {
    data() {
        return {
            params: '?paginate=0&per_page=0&keyword=car_setting',
            setting: {
                type: 'car_setting',
                value: {
                    max_no_car: 1,
                    max_no_booking: 1,
                }
            },
            settingReady: false,
        }
    },
    async mounted() {
        this.show()
    },
    methods: {
        async show(update) {
            alertService.loading();
            this.settingReady = false
            const response = await facilitySettingPaymentRegisterService.show(this.params);
            if(response.data.length > 0) this.setting = response.data[0]
            this.settingReady = true
            alertService.close();
            if(update) {
                alertService.success(this.$t("facility.setting.register-information"), this.$t("facility.setting.updated-successfuly"));
            }
        },

        async update$() {

            var callback = async () => {
                await facilitySettingPaymentRegisterService.update(this.setting)
                    .then((response) => {
                        this.show('update')
                    }).catch((error) => {
                        console.log(error)
                    });
            }
            alertService.confirmation(callback, this.$t("facility.global.confirmation"), this.$t("facility.global.are-you-sure"));

        },
    }

}