import { systemConfigService } from "../../../../../services/facility/system-config/system-config.service";
import { alertService } from "../../../../../services/alert/alert.service";

export default {
    data() {
        return {
            data: {
                type: "notification_setting",
                value: {
                    email_only: true,
                    email_and_sms: false,
                },
            },
            filter: {
                paginate: 0,
                per_page: 0,
                keyword: "notification_setting",
            },
        };
    },
    async mounted() {
        await this.show(this.filter);
    },
    methods: {
        async show(data) {
            alertService.loading();
            let result = await systemConfigService.list(data);

            if (result.data.length >= 1) {
                this.data = result.data[0];
            }
            alertService.close();
        },
        async submit$() {
            var callback = async () => {
                await systemConfigService
                    .store(this.data)
                    .then(async (success) => {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.setting.updated-successfuly")
                        );
                        await this.show(this.filter);
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.global.are-you-sure")
            );
        },
    },
};
