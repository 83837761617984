import {alertService} from "@/services/alert/alert.service";
import {facilitySettingInventoryService} from "@/services/facility/setting/inventory.service";

export default {
    data() {
        return {
            parkings: [],
            parkingsReady: false,
            form: {
                parking_id: 0,
                booking_count: 1,
                is_color_indicates: true,
            },
            inventory: []
        }
    },
    async mounted() {
        this.parkingList()
    },
    methods: {
        async parkingList() {
            this.parkingsReady = false
            alertService.loading();
            await facilitySettingInventoryService.parkingList().then((response) => {
                this.parkings = response.data.data
                this.parkingsReady = true
                if(this.parkings.length > 0) {
                    this.form.parking_id = this.parkings[0].id
                    this.show(this.form.parking_id)
                }
                alertService.close();
            }).catch((error) => {
                alertService.close();
                console.log(error)
            });
        },

        async show(id, update) {
            alertService.loading();
            await facilitySettingInventoryService.show(id).then((response) => {
                this.inventory = response.data
                if(this.inventory.length > 0) {
                    this.form  = {
                        parking_id: this.inventory[0].parking_id,
                        booking_count: this.inventory[0].booking_count,
                        is_color_indicates: this.inventory[0].is_color_indicates == 1 ? true : false
                    }
                }
                alertService.close();
                if(update) {
                    alertService.success(this.$t("facility.global.success"), this.$t("facility.setting.updated-successfuly"));
                }
            }).catch((error) => {
                this.inventory = []
                this.form.booking_count = 0
                this.form.is_color_indicates = false
                alertService.close();
                console.log(error)
            });
        },

        async update$() {

            var callback = async () => {
                await facilitySettingInventoryService.update(this.form)
                    .then((response) => {
                        this.show(this.form.parking_id, 'update')
                    }).catch((error) => {
                        console.log(error)
                    });
            }
            alertService.confirmation(callback, this.$t("facility.global.confirmation"), this.$t("facility.global.are-you-sure"));

        },

        changeParking(id) {
            this.form.parking_id = id
            this.show(this.form.parking_id)
        },
    }

}