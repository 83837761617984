import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class SystemConfigService {
    async store(data) {
        promise = api.client.post(config.end_point.facility.system_configs, data);
        return promise;
    }

    async update(id, data) {
        promise = api.client.put(config.end_point.facility.system_configs + '/' + id, data);
        return promise;
    }

    async show(id) {
        promise = api.client.get(config.end_point.facility.system_configs + '/' + id);
        return promise;
    }

    async delete(id) {
        promise = api.client.delete(config.end_point.facility.system_configs + '/' + id);
        return promise;
    }

    async list(data) {
        promise = await api.client.get(config.end_point.facility.system_configs, {params: data});
        return promise;
    }

}

export const systemConfigService = new SystemConfigService();
