import {alertService} from "@/services/alert/alert.service";
import {facilitySettingProfileService} from "@/services/facility/setting/profile.service";
import config from '@/config/app.config';

export default {
    data() {
        return {
            user: {
                airport_name_jp: '',
                airport_name_en: '',
                postal_code: '',
                prefecture: '',
                address: '',
                phone_number: '',

            },
            userReady: false,
        }
    },
    async mounted() {
        this.show(config.airport_id)
    },
    methods: {
        async show(id, update) {
            alertService.loading();
            this.userReady = false
            const response = await facilitySettingProfileService.show(id);
            this.user = response.data
            this.userReady = true
            alertService.close();
            if(update) {
                alertService.success(this.$t("facility.setting.profile-info"), this.$t("facility.setting.updated-successfuly"));
            }
        },

        async update$() {

            var callback = async () => {
                await facilitySettingProfileService.update(this.user.id, this.user)
                    .then((response) => {
                        this.show(config.airport_id, 'update')
                    }).catch((error) => {
                        console.log(error)
                    });
            }
            alertService.confirmation(callback, this.$t("facility.global.confirmation"), this.$t("facility.global.are-you-sure"));

        },

    }

}