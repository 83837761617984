import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilitySettingPaymentRegisterService {
    show(params) {
        promise = api.client.get(config.end_point.facility.airport.config + params);
        return promise;
    }
    
    update(data) {
        promise = api.client.post(config.end_point.facility.airport.config, data);
        return promise;
    }
}

export const facilitySettingPaymentRegisterService = new FacilitySettingPaymentRegisterService();
