import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilitySettingInventoryService {
    show(id) {
        promise = api.client.get(config.end_point.facility.airport.inventory + '?parking_id=' + id);
        return promise;
    }
    
    update(data) {
        promise = api.client.post(config.end_point.facility.airport.inventory, data);
        return promise;
    }

    async parkingList() {
        promise = await api.client.get(config.end_point.facility.parkings +`?paginate=1&per_page=10&airport_id=${config.airport_id}`);
        return promise;
    }
}

export const facilitySettingInventoryService = new FacilitySettingInventoryService();
